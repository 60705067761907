exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cookingcourses-index-tsx": () => import("./../../../src/pages/cookingcourses/index.tsx" /* webpackChunkName: "component---src-pages-cookingcourses-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mobileapp-index-tsx": () => import("./../../../src/pages/mobileapp/index.tsx" /* webpackChunkName: "component---src-pages-mobileapp-index-tsx" */),
  "component---src-pages-privacypolicy-index-tsx": () => import("./../../../src/pages/privacypolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-index-tsx" */),
  "component---src-pages-termsandconditions-index-tsx": () => import("./../../../src/pages/termsandconditions/index.tsx" /* webpackChunkName: "component---src-pages-termsandconditions-index-tsx" */),
  "component---src-templates-all-cuisines-template-tsx": () => import("./../../../src/templates/all-cuisines-template.tsx" /* webpackChunkName: "component---src-templates-all-cuisines-template-tsx" */),
  "component---src-templates-cuisine-recipes-template-tsx": () => import("./../../../src/templates/cuisine-recipes-template.tsx" /* webpackChunkName: "component---src-templates-cuisine-recipes-template-tsx" */),
  "component---src-templates-recipe-template-tsx": () => import("./../../../src/templates/recipe-template.tsx" /* webpackChunkName: "component---src-templates-recipe-template-tsx" */)
}

